import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
    [
      _c(
        VCard,
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            VCardText,
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c(VIcon, { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-office-building-marker")
              ]),
              _vm._v("Cartórios > Listar Cartórios ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            {
              staticClass: "pa-5 my-3",
              attrs: { elevation: "0" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.botaoListarCartorios.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                {},
                [
                  _c(
                    VCol,
                    {
                      attrs: { cols: "3", xs: "2", sm: "2", md: "3", lg: "2" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          elevation: "0",
                          "prepend-icon": "mdi-chevron-right",
                          "x-small": "",
                          label: "Código IBGE Cartório",
                          hint: "Informe o Código IBGE do cartório",
                          placeholder: "Ex.:0000349",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.cd_ibge_comarca,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_ibge_comarca", $$v)
                          },
                          expression: "filtros.cd_ibge_comarca"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "2" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          small: "",
                          elevation: "0",
                          label: "Número do Cartório",
                          hint: "Informe o número para efetuar a busca",
                          placeholder: "Ex.: 1",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.nu_cartorio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nu_cartorio", $$v)
                          },
                          expression: "filtros.nu_cartorio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Nome do Cartório",
                          hint: "Informe o nome para efetuar a busca",
                          placeholder: "Ex.: Tabelionato",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.nm_cartorio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nm_cartorio", $$v)
                          },
                          expression: "filtros.nm_cartorio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { cols: "10", xs: "2", sm: "2", md: "2", lg: "2" }
                    },
                    [
                      _c(VSelect, {
                        attrs: {
                          solo: "",
                          small: "",
                          clearable: "",
                          items: _vm.listaUfs,
                          label: "UF",
                          "return-object": true
                        },
                        model: {
                          value: _vm.filtros.cd_uf,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_uf", $$v)
                          },
                          expression: "filtros.cd_uf"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { cols: "3", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Nome da Cidade",
                          hint: "Informe o nome da cidade",
                          placeholder: "Ex.: Curitiba",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.nm_municipio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nm_municipio", $$v)
                          },
                          expression: "filtros.nm_municipio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "text-right",
                      attrs: { sm: "12", md: "12", lg: "12" }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "green white--text",
                            "data-cy": "buscarCartoriosBtn"
                          },
                          on: { click: _vm.botaoListarCartorios }
                        },
                        [
                          _vm._v(" Buscar Cartórios "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemRetorno
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            attrs: {
              "data-cy": "dataTableCartorios",
              loading: _vm.loading,
              "no-data-text": "Não há cartórios listados",
              "loading-text": "Carregando Cartórios...",
              headers: _vm.headers,
              items: _vm.cartorios,
              "item-key": "cartorios",
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: "Cartórios por página:"
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.st_ativo",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.st_ativo != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.st_ativo != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.st_ativo,
                          callback: function($$v) {
                            _vm.$set(item, "st_ativo", $$v)
                          },
                          expression: "item.st_ativo"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.is_admin",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: {
                            color: item.is_admin != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.is_admin)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.status == "true"
                        ? _c("span", [_c("p", [_vm._v("Ativo")])])
                        : _vm._e(),
                      item.cd_status == "false"
                        ? _c("span", [_c("p", [_vm._v("Desabilitado")])])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 grey white--text",
                          attrs: {
                            elevation: "0",
                            width: "90%",
                            small: "",
                            "data-cy": "editarCartorio" + props.index
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(props.item)
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "", small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [_vm._v("mdi-office-building-marker ")]
                          ),
                          _vm._v("Ver Cartório ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialogEditar
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogEditar,
                callback: function($$v) {
                  _vm.dialogEditar = $$v
                },
                expression: "dialogEditar"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialogEditar }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "2", align: "left" } },
                            [
                              _c(VToolbarTitle, [
                                _vm._v("Visualizar Cartório")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c("EditarCartorio", {
                        attrs: {
                          cartorio: _vm.cartorio,
                          validarForm: _vm.validarForm
                        },
                        on: {
                          formValidado: function($event) {
                            _vm.validarForm = $event
                          },
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          fecharModal: _vm.closeDialogEditar
                        }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: { color: "success white--text" },
                          on: { click: _vm.validarSalvarCartorio }
                        },
                        [
                          _c(VIcon, {}, [_vm._v("mdi-chevron-right")]),
                          _vm._v("Salvar Alterações ")
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: { color: "grey white--text" },
                          on: { click: _vm.closeDialogEditar }
                        },
                        [
                          _c(VIcon, {}, [_vm._v("mdi-chevron-right")]),
                          _vm._v("Fechar ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }