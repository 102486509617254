<template>
    <div>
        <v-card>
            <v-container>
                <!-- <form ref="cartorioForm"> -->
                <v-form
                    ref="form"
                    v-model="valid"
                    @keyup.native.enter="salvarCartório;"
                >
                    <v-row class="mt-8">
                        <v-col cols="12" sm="1" md="1"></v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-icon
                                class="grey--text ma-3 pa-3 mx-auto my-auto"
                                size="120"
                                elevation-8
                            >
                                mdi-information-outline
                            </v-icon>
                            <v-card-text
                                class="
                                        grey--text
                                        display-1
                                        font-weight-black
                                    "
                            >
                                Detalhes do Cartório
                            </v-card-text>
                            <v-card-text
                                class="
                                        grey--text
                                        font-weight-black
                                    "
                            >
                                Através dessa tela, acesse todos os detalhes do
                                cartório selecionado podendo consultar dados de
                                contato e localização
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6" md="8">
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        ID do cartório
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="id"
                                        readonly
                                        disabled
                                        label="ID"
                                        type="text"
                                        solo
                                        v-model="cartorio.id"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Código IBGE
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="CodIbge"
                                        readonly
                                        disabled
                                        label="Código IBGE do cartório"
                                        type="text"
                                        solo
                                        v-model="cartorio.cd_ibge_comarca"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Número do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="numero"
                                        readonly
                                        label="Número do Cartório"
                                        type="text"
                                        disabled
                                        solo
                                        v-model="cartorio.nu_cartorio"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="11">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Nome do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="NomeCartório"
                                        label="Nome do Cartório"
                                        type="text"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.nm_cartorio"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Telefone do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="telefone"
                                        label="Telefone do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.telefone"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="7">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        E-mail do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        name="EmailCartorio"
                                        label="E-mail do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.email"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Cep do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="cep"
                                        label="CEP do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.cep"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Endereço do Cartório
                                    </v-card-text>
                                    <v-text-field
                                        name="endereco"
                                        label="Endereço do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.endereco"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="2">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Número
                                    </v-card-text>
                                    <v-text-field
                                        name="numero"
                                        label="Número do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.endereco_numero"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Complemento
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="complemento"
                                        label="Complemento do Cartório"
                                        solo
                                        v-model="cartorio.complemento"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Bairro
                                    </v-card-text>
                                    <v-text-field
                                        name="bairro"
                                        label="Bairro do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.bairro"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Cidade
                                    </v-card-text>
                                    <v-text-field
                                        name="cidade"
                                        label="Cidade do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.nm_cidade"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Estado
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="estado"
                                        label="Estado do Cartório"
                                        :rules="requiredRules"
                                        solo
                                        v-model="cartorio.cd_uf"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        CNPJ
                                    </v-card-text>
                                    <v-text-field
                                        name="cnpj"
                                        label="CNPJ do Cartório"
                                        :rules="[
                                            rules.required,
                                            rules.cpfOrCnpj
                                        ]"
                                        v-maska="'##.###.###/####-##'"
                                        solo
                                        v-model="cartorio.cnpj"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-card-text
                                        class="grey--text font-weight-light"
                                    >
                                        Responsável
                                    </v-card-text>
                                    <v-text-field
                                        name="responsavel"
                                        label="Responsável do Cartório"
                                        solo
                                        v-model="
                                            cartorio.nm_responsavel_tabelionato
                                        "
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <!-- </form> -->
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { eventFecharModal } from '../helpers/Utilitarios';
import { OnRules } from 'vuetify-js-utils';

export default {
    mixins: [OnRules],
    name: 'DetalheCartorio',
    props: {
        cartorio: {
            type: Object,
            required: false
        },
        validarForm: {
            type: Boolean,
            required: false
        },
        showActionSalvar: Boolean
    },
    data() {
        return {
            requiredRules: [v => !!v || 'Campo Obrigatório'],
            valid: false
        };
    },
    methods: {
        eventFecharModal,
        submit() {}
    },
    watch: {
        validarForm(val) {
            if (val) {
                this.$refs.form.validate();
                this.$emit('formValidado', false);
            }
        },
        valid(val) {
            this.$emit('setIsInvalid', !val);
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    mounted() {
        document.addEventListener('keyup', this.eventFecharModal);
    }
};
</script>

<style scoped></style>
